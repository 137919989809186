import React, { useEffect } from 'react';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import '../Styles/Germany.css';
import germanyImage from '../Assets/images/StudyGermany.jpeg'
import { useNavigate } from "react-router-dom";

function GermanyStep2() {
    const navigate = useNavigate();
    const handleNextPage=()=>{
        navigate('/germany/step3');
    }
    const handlePreviousPage=()=>{
        navigate('/germany');
    }
    
    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);


  return (
            <>
            <Navbar />
            <div className="germany-step2-container">
      <main className="germany-step2-main-content">
        <section className="germany-step2-intro-section">
          <h1 className="germany-step2-heading-primary">Study in Germany</h1>
          <img src={germanyImage} alt="Germany" className="germany-step2-image-cover" />
          <p className="germany-step2-paragraph">
          Studying in Germany offers a high-quality education at a relatively low cost. Public universities often have low or no tuition fees, making it an affordable option for international students. Germany is known for its strong emphasis on research and innovation, particularly in engineering, technology, and the sciences. The country provides a rich cultural experience, with diverse cities and a high standard of living. Many programs are available in English, and there are extensive support services for international students. Additionally, Germany’s central location in Europe allows easy travel across the continent.          </p>
   
        </section>

        <section className="germany-step2-educational-institutions">
          <h2 className="germany-step2-heading-secondary">Types of Educational Institutions</h2>
          <div className="germany-step2-institution-grid">
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Universities (Universitäten)</h3>
              <p> Offer a wide range of academic disciplines, including humanities, social sciences, natural sciences, and engineering. They typically provide Bachelor's, Master's, and doctoral programs.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Universities of Applied Sciences (Fachhochschulen)</h3>
              <p>Focus on practical and professional education in fields like engineering, business, and social work. They often offer Bachelor's and Master's degrees with a strong emphasis on practical skills.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Art and Music Academies (Kunsthochschulen, Musikhochschulen)</h3>
              <p>Specialized institutions offering programs in visual arts, performing arts, music, and design.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Technical Universities (Technische Universitäten, TUs)</h3>
              <p>Emphasize engineering and technological disciplines, combining theoretical and practical approaches.</p>
            </div>
          </div>
        </section>

        <section className="germany-step2-admission-requirements">
          <h2 className="germany-step2-heading-secondary">Admission Requirements</h2>
          <p className="germany-step2-paragraph">
          Generally, you need a recognized secondary school diploma (Abitur for Germans or equivalent for international students) to apply for undergraduate programs. For postgraduate programs, a relevant Bachelor’s degree or equivalent is required.         </p>
          <div className="germany-step2-requirement-list">
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Undergraduate (Bachelor's) Studies</h3>
              <p>German universities typically do not have a strict minimum GPA requirement for undergraduate admissions. Instead, they focus on the overall quality and recognition of your secondary school qualifications.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Competitive Programs</h3>
              <p>For highly competitive programs or those at prestigious universities, a higher GPA may be advantageous. For example, programs in fields like medicine, engineering, or business at top universities might have higher expectations.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Graduate (Master's) Studies</h3>
              <p>Many master's programs require a bachelor's degree with a good academic record. While specific GPA requirements can vary, a GPA of around 2.5 to 3.0 on a 4.0 scale (or its equivalent) is often considered acceptable.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Competitive Programs</h3>
              <p>For competitive programs, especially at top universities, a higher GPA (around 3.5 or above) may be expected. The exact threshold can depend on the program and the applicant pool.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Doctoral (Ph.D.) Studies</h3>
              <p>For doctoral studies, universities generally focus more on your research experience, academic background, and the alignment of your research interests with faculty expertise than on GPA alone.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Academic Excellence</h3>
              <p> A strong academic record with high grades in relevant coursework and research experience is important. Specific GPA requirements are less standardized and more flexible, but a high GPA can enhance your application.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">International Considerations</h3>
              <p> International students may need to convert their GPA into the German grading system. German universities often use a grading scale from 1.0 (very good) to 5.0 (fail). You may need to provide a GPA equivalency or conversion if your grades are in a different system.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Recognition of Qualifications</h3>
              <p> Ensure that your previous qualifications are recognized and equivalent to the German system. Tools like the Anabin database can help in checking the recognition status.</p>
            </div>
            <h2 className="germany-step2-heading-secondary">Additional Factors</h2>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary"> Overall Profile</h3>
              <p>Besides GPA, universities also consider other aspects of your academic and professional profile, such as letters of recommendation, statement of purpose, and relevant experience.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Program-Specific Requirements</h3>
              <p> Always check the specific admission criteria for each program and university, as they can differ significantly.</p>
            </div>
          </div>
        </section>
       
        <section className="germany-step2-language-proficiency">
          <h2 className="germany-step2-heading-secondary">Language Proficiency</h2>
          <p className="germany-step2-paragraph">
          Many programs are taught in German, requiring proof of German language proficiency (e.g., TestDaF, DSH). However, an increasing number of programs, especially at the Master’s level, are offered in English, requiring proof of English proficiency (e.g., TOEFL, IELTS).
          </p>
          <div className="germany-step2-proficiency-list">

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Undergraduate (Bachelor’s) Studies</h3>
              <div className='germany-step2-card'>
              <h3 className="germany-step2-heading-tertiary"> German-Taught Programs:</h3>
              <ul className="germany-step2-language-list">
            <li><strong>TestDaF</strong> - Minimum score of 4 in each section (Reading, Listening, Writing, and Speaking) for most programs.</li>
            <li><strong>DSH (Deutsche Sprachprüfung für den Hochschulzugang)</strong> - Generally, DSH-2 or higher (equivalent to a B2-C1 level) is required.</li>
            <li><strong>Goethe-Zertifikat</strong> - Goethe-Zertifikat C1 is typically required for higher education.</li>
            </ul>
             
              </div>
              <div className='germany-step2-card'>
              <h3 className="germany-step2-heading-tertiary">English-Taught Programs:</h3>
              <ul className="germany-step2-language-list">
            <li><strong>TOEFL</strong> - Minimum score of 80-90 (internet-based test), with some programs requiring higher scores.</li>
            <li><strong>IELTS</strong> - Minimum score of 6.0-7.0 overall, with some programs requiring higher scores.</li>
            </ul>
            </div>
            </div>



            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Graduate (Master’s) Studies</h3>
              <div className='germany-step2-card'>
              <h3 className="germany-step2-heading-tertiary"> German-Taught Programs:</h3>
              <ul className="germany-step2-language-list">
            <li><strong>TestDaF</strong> - Similar to undergraduate requirements, a minimum score of 4 in each section is commonly required.</li>
            <li><strong>DSH</strong> - Typically, DSH-2 or higher is expected.</li>
            <li><strong>Goethe-Zertifikat</strong> - Goethe-Zertifikat C1 is generally needed.</li>
            </ul>

              </div>
              <div className='germany-step2-card'>
              <h3 className="germany-step2-heading-tertiary">English-Taught Programs:</h3>

              <ul className="germany-step2-language-list">
            <li><strong>TOEFL</strong> - Minimum score of 85-100, depending on the program.</li>
            <li><strong>IELTS</strong> - Minimum score of 6.5-7.5 overall, depending on the program.</li>
            </ul>
            </div>
            </div>



            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Doctoral (Ph.D.) Studies</h3>
              <div className='germany-step2-card'>
              <h3 className="germany-step2-heading-tertiary"> German-Taught Programs:</h3>

              <ul className="germany-step2-language-list">
            <li><strong>TestDaF</strong> - A high score in TestDaF might be needed, often 4 or higher in each section.</li>
            <li><strong>DSH</strong> - DSH-2 or better is usually expected.</li>
            <li><strong>Goethe-Zertifikat</strong> - Goethe-Zertifikat C1 or higher is required for most programs.</li>
            </ul>

              </div>
              <div className='germany-step2-card'>
              <h3 className="germany-step2-heading-tertiary">English-Taught Programs:</h3>
              <ul className="germany-step2-language-list">
            <li><strong>TOEFL</strong> -  Typically, a minimum score of 90-100 is required.</li>
            <li><strong>IELTS</strong> - A minimum score of 7.0-7.5 is often expected.</li>
            </ul>
            </div>
            </div>

          </div>
        </section>


        <section className="germany-step2-language-proficiency">

          <h2 className="germany-step2-heading-secondary">General Notes</h2>
          <div className="germany-step2-proficiency-list">

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Program-Specific Requirements</h3>             
              <p>Always check the specific language requirements for each program and university, as they can vary.</p>
            </div>

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary"> Language Certificates</h3>             
              <p>Certificates must usually be recent, often within the last two years.</p>
            </div>

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Alternative Proof</h3>             
              <p> Some universities might accept other forms of proof of language proficiency, such as completion of previous education in the language of instruction.</p>
            </div>

          </div>
        </section>


        <section className="germany-step2-language-proficiency">

            <h2 className="germany-step2-heading-secondary">Entrance Exams</h2>
            <p className="germany-step2-paragraph">
            Some programs, especially in fields like medicine or art, may require specific entrance exams or portfolios.
                      </p>
          
        </section>
       

      
        <section className="germany-step2-language-proficiency">
            <h2 className="germany-step2-heading-secondary">Application Deadlines / Intakes</h2>           
            <div className="germany-step2-proficiency-list">

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Winter Semester (Wintersemester)</h3>             
              <p>Start Date: The winter semester generally starts on October 1st.</p>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Application Deadlines</h3>             
                <p>EU/EEA Students: Applications are often due by mid-July.</p>
                <p>Non-EU Students: Deadlines can be earlier, often by mid-April or earlier, depending on the university and program.</p>
                <p>Semester Duration: The semester runs from October to March, with lectures typically beginning in October and ending in February, followed by a break and exam period.</p>
              </div>
            </div>

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Summer Semester (Sommersemester)</h3>             
              <p>Start Date: The summer semester usually starts on April 1st.</p>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Application Deadlines</h3>             
                <p> EU/EEA Students: Applications are typically due by mid-January.</p>
                <p>Non-EU Students: Deadlines are often earlier, around mid-October of the previous year.</p>
                <p>Semester Duration: The semester runs from April to September, with lectures starting in April and ending in July, followed by exams and a break.</p>
              </div>
            </div>

            <h2 className="germany-step2-heading-secondary">Additional Notes</h2>    

            <div className="germany-step2-card">
            <h3 className="germany-step2-heading-tertiary">Specific Programs</h3>             
              <p>Some programs may have different or additional deadlines, so it's important to check the specific requirements of the university and program you are applying for.</p>
            </div>

            <div className="germany-step2-card">
            <h3 className="germany-step2-heading-tertiary">Application Process</h3>             
              <p>Applications are generally submitted online through the university’s application portal or through platforms like UniAssist for international students.</p>
            </div>


            </div>
        </section>




        <section className="germany-step2-application-process">
          <h2 className="germany-step2-heading-secondary">Application Process</h2>
          <div className="germany-step2-application-list">
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">University Application</h3>
              <p>Applications are usually submitted directly to the university or through a centralized application system like Uni-Assist for international students.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Documents Required</h3>
              <p>Commonly required documents include academic transcripts, proof of language proficiency, a motivational letter, and sometimes a CV or portfolio.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Visa and Residence Permit</h3>
              <p>Non-EU/EEA students need a student visa to enter Germany and a residence permit to stay. The visa application requires proof of admission, financial means, and health insurance.</p>
            </div>
          </div>
        </section>

        <section className="germany-step2-tuition-fees">
          <h2 className="germany-step2-heading-secondary">Tuition Fees and Financial Support</h2>
          <div className="germany-step2-tuition-list">

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Public Universities</h3>

              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Tuition Fees</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">For Most Programs</h3>
              <p>Public universities in Germany typically do not charge tuition fees for undergraduate programs. However, there may be a nominal semester fee, which usually ranges from €150 to €350 per semester. This fee often covers administrative costs, student services, and public transportation.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">For Master's Programs</h3>
              <p>Many public universities also offer tuition-free master’s programs, especially if the student has completed their undergraduate studies in Germany. However, for non-EU international students or for certain specialized programs, fees might apply. These fees generally range from €1,000 to €3,000 per year.</p>
            </div>
            </div>

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Additional Costs</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Semester Fee</h3>
              <p>Includes contributions to student unions, administrative services, and sometimes public transport tickets.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Living Costs</h3>
              <p> Expect to budget around €800 to €1,200 per month for living expenses, including accommodation, food, health insurance, and other personal costs.</p>
            </div>

           

            </div>

            </div>


          
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Private Universities</h3>

              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Tuition Fees</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">General Range</h3>
              <p>Private universities in Germany typically charge tuition fees. These can vary widely, generally ranging from €5,000 to €20,000 per year, depending on the program and institution.</p>
            </div>
            </div>

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Additional Costs</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Semester Fee</h3>
              <p>Private institutions may also have additional fees for student services and administrative costs.</p>
            </div>
            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Living Costs</h3>
              <p>  Similar to public universities, living expenses are an additional consideration.</p>
            </div>
            </div>

            <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Special Cases</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Non-EU International Students</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Certain Programs</h3>

              <p>Some public universities might charge tuition fees for non-EU international students, especially for specific programs or courses.</p>
              </div>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Range</h3>
              <p>Fees for non-EU students can vary significantly but generally fall between €1,000 and €3,000 per year for undergraduate programs. For master’s programs, fees may be higher, depending on the institution and program.</p>
              </div>
              </div>


              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">State-Specific Fees</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Baden-Württemberg</h3>

              <p>This state has specific tuition fees for non-EU international students, typically around €1,500 per year.</p>
              </div>
              </div>


              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Scholarships and Financial Aid</h3>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Scholarships</h3>
<p>Numerous scholarships are available for international students, such as those from DAAD (German Academic Exchange Service), which can help cover tuition and living expenses.</p>
              </div>
              <div className="germany-step2-card">
              <h3 className="germany-step2-heading-tertiary">Part-Time Work</h3>
<p>International students are allowed to work part-time, which can help offset living costs.</p>
              </div>
              </div>

            </div>

            </div>


          </div>
        </section>

        <section className="germany-step2-cost-of-living">
          <h2 className="germany-step2-heading-secondary">Cost of Living</h2>
          <div className="germany-step2-card">
          <h3 className="germany-step2-heading-tertiary">Living Expenses</h3>
           <p>Monthly living costs in Germany are estimated to be between €700 and €1,000, covering accommodation, food, transportation, and personal expenses. Costs vary depending on the city and lifestyle.</p> 
          </div>

          <div className="germany-step2-card">
          <h3 className="germany-step2-heading-tertiary">Accommodation </h3>
           <p>Options include student dormitories, shared apartments (WG), or private rentals. University housing is often more affordable but may have long waiting lists.</p> 
          </div>
        </section>




        <section className="germany-step2-student-life">
          <h2 className="germany-step2-heading-secondary">Student Life</h2>

          <div className="germany-step2-card">
          <h3 className="germany-step2-heading-tertiary">Student Services</h3>
           <p>Universities offer various services, including academic advising, career counseling, and student organizations.</p> 
          </div>

          <div className="germany-step2-card">
          <h3 className="germany-step2-heading-tertiary">Cultural Activities</h3>
           <p>Germany has a rich cultural scene, with numerous museums, theaters, festivals, and recreational opportunities. Students often have access to discounts on cultural and recreational activities.</p> 
          </div>

          <div className="germany-step2-card">
          <h3 className="germany-step2-heading-tertiary">Language Learning</h3>
           <p>Even if your program is in English, learning German can enhance your experience and help with everyday life in Germany.</p> 
          </div>

        </section>

        <section className="germany-step2-post-graduation">
          <h2 className="germany-step2-heading-secondary">Post-Graduation Opportunities</h2>

          <div className="germany-step2-card">
            <h3 className="germany-step2-heading-tertiary">Job Market</h3>
            <p>Germany has a strong job market, especially in sectors like engineering, IT, and business. Graduates often have good prospects for finding employment.</p>
          </div>

          <div className="germany-step2-card">
            <h3 className="germany-step2-heading-tertiary">Residence Permit for Job Search</h3>
            <p>Graduates from German universities can apply for an 18-month residence permit to look for a job related to their field of study.</p>
          </div>

          <div className="germany-step2-card">
            <h3 className="germany-step2-heading-tertiary">Further Studies</h3>
            <p>Germany offers various opportunities for further education and research, including doctoral programs and postdoctoral research positions.</p>
          </div>


        </section>

        <section className="germany-step2-top-universities">
          <h2 className="germany-step2-heading-secondary">BASIC CHECKLIST</h2>
          <ul className="germany-step2-language-list">
            <li><strong>Choose a Program and University</strong></li>
            <li><strong>Research and select your preferred course and institution</strong></li>
            <li><strong>Check Admission Requirements</strong></li>
            <li><strong>Confirm educational and language proficiency requirements</strong></li>
            <li><strong>Prepare Application Documents</strong></li>
            <li><strong>Gather transcripts, CV, letters of recommendation, and proof of language proficiency</strong></li>
            <li><strong>Apply</strong></li>
            <li><strong>Submit applications according to university guidelines and deadlines</strong></li>
            <li><strong>Secure Funding</strong></li>
            <li><strong>Explore scholarships and plan your budget for living expenses</strong></li>
            <li><strong>Obtain a Student Visa</strong></li>
            <li><strong>Apply at the German embassy with proof of acceptance, financial means, and health insurance</strong></li>
            <li><strong>Arrange Accommodation</strong></li>
            <li><strong>Find student housing or a private rental</strong></li>
            <li><strong>Get Health Insurance</strong></li>
            <li><strong>Obtain German health insurance or verify existing coverage</strong></li>
            <li><strong>Plan Your Arrival</strong></li>
            <li><strong>Arrange travel and register with local authorities upon arrival</strong></li>
            <li><strong>Enroll and Attend Orientation</strong></li>
            <li><strong>Complete enrollment and participate in orientation activities</strong></li>
          </ul>
        </section>

        <section className="germany-step2-top-universities">
          <h2 className="germany-step2-heading-secondary">Top 10 Public Universities</h2>
          <ul className="germany-step2-university-list">
            <li><strong>Technical University of Munich (TUM)</strong> - Munich: Engineering, natural sciences, technology, and business.</li>
            <li><strong>University of Heidelberg</strong> - Heidelberg: Humanities, social sciences, life sciences, and medicine.</li>
            <li><strong>Humboldt University of Berlin</strong> - Berlin: Humanities, social sciences, natural sciences, and law.</li>
            <li><strong>University of Freiburg</strong> - Freiburg: Environmental sciences, life sciences, humanities, and engineering.</li>
            <li><strong>RWTH Aachen University</strong> - Aachen: Engineering, technology, and natural sciences.</li>
            <li><strong>University of Mannheim</strong> - Mannheim: Business, economics, social sciences, and humanities.</li>
            <li><strong>University of Göttingen</strong> - Göttingen: Natural sciences, social sciences, and humanities.</li>
            <li><strong>University of Stuttgart</strong> - Stuttgart: Engineering, natural sciences, and automotive technology.</li>
            <li><strong>Technical University of Berlin (TU Berlin)</strong> - Berlin: Engineering, technology, and computer science.</li>
            <li><strong>University of Leipzig</strong> - Leipzig: Humanities, social sciences, and natural sciences.</li>
          </ul>
        </section>
      </main>
      <div className="pagination">
                    <button className="previousBtn" onClick={handlePreviousPage}>Previous Page</button>
                    <button className="nextBtn" onClick={handleNextPage}>Next Page</button>
                </div>
    </div>
      <Footer />
    </>
  );
}

export default GermanyStep2;
